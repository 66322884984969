
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$template-generator-primary: mat.define-palette(mat.$indigo-palette);
$template-generator-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$template-generator-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$template-generator-theme: mat.define-light-theme((
  color: (
    primary: $template-generator-primary,
    accent: $template-generator-accent,
    warn: $template-generator-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($template-generator-theme);














// TODO: Custom ----------------------------------------------------------------------

@import '@angular/material/theming';
@include mat-core();

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }



/* ----- Buttons ----- */

.base_buttons{
    background-color: #0085a3;
    color: #FFF;
    vertical-align: middle;
    min-width: 100px !important;
    margin: 5px;
    text-transform: uppercase;
}


$custom-primary: (
   50 : #e1f3f8,
   100 : #b3e1ed,
   200 : #81cde1,
   300 : #4fb8d5,
   400 : #29a7cc,
   500 : #0085a3,
   600 : #007a99,
   700 : #006d8d,
   800 : #006181,
   900 : #004f6e,
   A100 : #b3e1ed,
   A200 : #81cde1,
   A400 : #29a7cc,
   A700 : #004f6e,
   contrast: (
       50 : #000000,
       100 : #000000,
       200 : #000000,
       300 : #000000,
       400 : #000000,
       500 : #ffffff,
       600 : #ffffff,
       700 : #ffffff,
       800 : #ffffff,
       900 : #ffffff,
       A100 : #000000,
       A200 : #000000,
       A400 : #000000,
       A700 : #ffffff,
   )
);
$my-app-primary: mat-palette($custom-primary);

$my-app-accent: mat-palette($custom-primary);

$my-app-warn: mat-palette($custom-primary);


$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include angular-material-theme($my-app-theme);


